import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAttendances(ctx, data) {
      return useJwt.getAttendances(data).then(response => response);
    },
    getApartmentLayout(ctx, params) {
      return useJwt.getApartmentLayout(params).then(response => response);
    },
    getApartmentDetail(ctx, id) {
      return useJwt.getApartmentDetail(id).then(response => response);
    },
    createApartment(ctx, data) {
      return useJwt.createApartment(data).then(response => response);
    },
    updateApartment(ctx, data) {
      return useJwt.updateApartment(data).then(response => response);
    },
    exportAttendances(ctx, data) {
      return useJwt.exportAttendances(data).then(response => response);
    },
    deleteApartments(ctx, data) {
      return useJwt.deleteApartments(data).then(response => response);
    },
  },
};
