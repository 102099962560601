import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMeetings(ctx, data) {
      return useJwt.getMeetings(data).then(response => response);
    },
    getMeetingsForWorkLog(ctx, workLogId) {
      return useJwt.getMeetingsFromWorkLog(workLogId).then(response => response);
    },
    getApartmentLayout(ctx, params) {
      return useJwt.getApartmentLayout(params).then(response => response);
    },
    getApartmentDetail(ctx, id) {
      return useJwt.getApartmentDetail(id).then(response => response);
    },
    createApartment(ctx, data) {
      return useJwt.createApartment(data).then(response => response);
    },
    updateApartment(ctx, data) {
      return useJwt.updateApartment(data).then(response => response);
    },
    importApartments(ctx, data) {
      return useJwt.importApartments(data).then(response => response);
    },
    exportApartments(ctx, data) {
      return useJwt.exportApartments(data).then(response => response);
    },
    deleteApartments(ctx, data) {
      return useJwt.deleteApartments(data).then(response => response);
    },
  },
};
